<template>
  <a-card>
    <div slot="title">基本信息</div>
    <basis-form ref="basis" />
    <a-card :bordered="false">
      <a-row type="flex" align="middle" justify="center">
        <a-col>
          <a-button type="primary" shape="round" :loading="loading" style="width:200px" @click="onSubmit">
            保存
          </a-button>
        </a-col>
      </a-row>
    </a-card>
  </a-card>
</template>

<script>
import BasisForm from './components/BasisForm'
import * as goodsserviceApi from '@/api/goodsservice'
import events from '@/components/MultiTab/events'
export default {
  name: 'GoodsManagementServiceCombinationEdit',
  data() {
    return {
      loading: false
    }
  },
  components: {
    BasisForm
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this
      this.goodsId = id
      this.$refs.basis.initData(this.goodsId)
    },
    onSubmit() {
      const {
        $refs: { basis },
        $notification
      } = this
      basis.validate().then(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = basis
            const form = formatForm()
            goodsserviceApi
              .combinationUpdate({ ...form })
              .then(res => {
                $notification['success']({ message: '操作成功' })
                this.close()
              })
              .finally(() => (this.loading = false))
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      events.$emit('close')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-input-number {
  width: 100%;
}
</style>
